'use strict';

export function init(options){
	var opt = $.extend(
		{
			text: '',
			success: true
		}, options);

	var showAsSucess = opt.success ? 'success' : 'error';

	$('body').append('<div class="toaster toaster--' + showAsSucess + '"><p>' + opt.text + '</p></div>');

	$('.toaster').animate({top: 0}, 500, function() {
		setTimeout(function() {
			$('.toaster').animate({ top: -50 }, 500, function() {
				$(this).remove();
			});
		}, 2000);
	});
}

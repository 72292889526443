'use strict';

var nameSpace = 'slider';

/**
 * dataAttrOptions extends default options with options defined in namespaced data attr.
 * @param  {object} defaults Default extend object
 * @param  {object} options  Extend to defaults
 * @return {object}          extended object
 */
function dataAttrOptions(defaults, options){
    var dataOptions = {},
        settings = {};

    if(jQuery.isEmptyObject(options)){
        return defaults;
    }

    for(let key in options){

        if(key.includes(nameSpace)){
            let stripedKey = key.replace(nameSpace, '');
            let newKey = stripedKey[0].toLowerCase() + stripedKey.slice(1);

            dataOptions[newKey] = options[key];
        }
    }

    // Recursively merge defaults and data attribute options.
    settings = $.extend(true, {}, defaults, dataOptions);

    return settings;
}

/**
 * dateSlider makes a slider with dates item inview
 * @param  {string} scope     css selector
 * @return {undefined}        nothing returned
 */
export function dateSlider(scope){
    let elm = $(scope);
    let elmCount = elm.find('> a').length;

    let defaults = {
        items: 10,
        loop: false,
        margin: 0,
        nav: true,
        navText: ['', ''],
        dots: false,
        startPosition: elmCount,
        autoplay: false,
        fallbackEasing: 'swing',
        responsive: {
            0: {
                items: 3
            },
            768: {
                items: 6
            },
            1024: {
                items: 10
            }
        }
    };

    if(elmCount <= 1) {
        return;
    }

    let settings = dataAttrOptions(defaults, elm.data());

    elm.owlCarousel(settings);

}

/**
 * init initilizes all sliders
 * @param  {object} scopes    a object keys as function names and value as selectors
 * @return {undefined}        nothing returned]
 */
export function init(scopes){
    for(let scope in scopes){
        this[scope].call(this, scopes[scope]);
    }
}

'use strict';

/*global jQuery*/
import './features';
import './polyfills';
import * as helpers from './helpers';
import * as slider from './sliders';
import * as toaster from './toaster';
import * as datepicker from './datepicker';

//Add an alias to the App, if we need to call App from the outside:
window.App = window.App || {};

window.App.slider = slider;
window.App.helpers = helpers;
window.App.toaster = toaster;


window.App.common = {
    // App.common.init runs on all pages
    init() {
        $.each(helpers, function(){
            if(typeof this === 'function'){
                this.call(this);
            }
        });
        datepicker.init('.datepicker');
    },
    slider() {
        slider.init({
            dateSlider: '.slider-date'
        });
    }
};


/**
 * Initial Application bootstrapping
 */

// jQuery document ready
/*$(() => {
    window.App.common.init();
});

// Window onload
window.onload = () => {
    window.App.common.slider();
};*/

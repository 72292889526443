'use strict';
/**
 * [scrollToElm description]
 * @param  {[string]} selector [css selector]
 * @param  {[number]} speed    [speed in ms]
 * @return {[undefined]}       [nothing returned]
 */
export function scrollToElm(selector, speed) {
    if(selector){
        var scrollToPos = selector ? $(selector).offset().top - 74 : 0;

        $('html, body').animate({
            scrollTop: scrollToPos
        }, speed || 500);
    }
}

/**
 * [expandCollapse]
 */
export function expandCollapse(){
    //Read more links
    $('.read-more-btn').click(function () {
        var el = $(this),
        moreTxt = el.parents().find('.read-more-content').first();

        if (moreTxt.is(':visible')) {
            moreTxt.slideUp();
            el.removeClass('collapse');
        } else {
            moreTxt.slideDown();
            el.addClass('collapse');
        }
        return false;
    });
}

/**
 * [autotab on keyup jumps to next input]
 * @param  {[string]} original      [css selector]
 * @param  {[string]} destination   [css selector]
 * @return {[undefined]}            [nothing returned]
 */
export function autotab(original, destination){
    if(!original){
        return false;
    }
    if (original.val().length === parseInt(original.attr('maxlength'), 10)){
        destination.focus();
        destination.bind('keyup', function(){
            if (destination.val().length === 0){
                destination.unbind('keyup');
                original.focus();
            }
        });
    }
}

/**
 * [togglevisibility]
 * @return {[undefined]}       [nothing returned]
 */
export function togglevisibility(){
    var doToggle = function(elm){
        var content = elm.data('togglevisibility');
        if(elm.is(':checked')){
            $(content).show();
        }else {
            $(content).hide();
        }
    };

    $('[data-togglevisibility]').each(function(){
        doToggle($(this));
    }).on('click', function(){
        doToggle($(this));
    });
}


export function tableOverflow(){
    $('.table').each(function(){

        $(this).wrap('<div class="table__wrapper" />');

        if($(this).width() > $(this).parent('.table__wrapper').width()) {
            $(this).parent('.table__wrapper').addClass('table__wrapper--overflow');
        }

    });
}

export function tableRowClick () {
    $('.table-row--clickable').on('click', function(){
        window.location = $(this).find('a').first().attr('href');
        return false;
    });
}

export function dataFx(){
    $('[data-fade-out]').each(function(){
        $(this).fadeOut($(this).data('fadeOut'));
    });
    $('[data-fade-in]').each(function(){
        $(this).fadeIn($(this).data('fadeIn'));
    });
}


export function windowOpen() {
     $('.js-window-open').on('click', function(e){
        let url = $(this).attr('href');
        let name = $(this).attr('target');
        let specs = $(this).data('specs');

        window.open(url, name, specs || 'width=600, height=300, titlebar=0, menubar=0, status=0');
        e.preventDefault();
    });
}

export function print(){
     $('.js-print').on('click', function(e){
        window.print();
        e.preventDefault();
    });
}

export function menu() {
    $('.mobile-nav').on('click', function() {
        $('.nav.nav--main').toggleClass('active');
    });
}
